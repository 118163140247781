import LinkOffIcon from '@mui/icons-material/LinkOff'
import { Box, Grid, Typography } from '@mui/material'
import { useGetList, useTranslate } from 'react-admin'
import { UploadFileOut } from '../../types/apiRequests'
import { getStatusForDescription } from '../../utils/getStatusForDescription'
import DownloadFile from '../Uploads/DownloadUploadFile'

export const FailedUploadsLogs = () => {
  const translate = useTranslate()

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return '-'
    return new Intl.DateTimeFormat('nl-NL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(new Date(dateString))
  }

  const { data: recentLogsData } = useGetList<UploadFileOut>(
    'upload_files',
    {
      pagination: { page: 1, perPage: 30 },
      sort: { field: 'created', order: 'DESC' },
      filter: { objectionNotFound: true },
    }
  )

  return (
    <>
      <Box>
        <Box
          height='50px'
          display="flex"
          justifyContent='center'
          borderBottom={'1px solid #28282C'}
          p="15px"
        >
          <Typography color='#28282C' variant="h6" fontWeight="bold">
            { translate('data.recentLogs')}
          </Typography>
        </Box>
      </Box>

      <Grid
        maxHeight='904px'
        overflow='auto'
        sx={{
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#DDDDE0',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#31679B',
          },
        }}
      >
        {recentLogsData?.map((file: UploadFileOut) => (
          <Grid
            key={file.id}
            container
            display="flex"
            direction="row"
            justifyContent="space-between"
            sx={{
              'borderBottom': '2px solid #DDDDE0',
              'flexWrap': 'nowrap',
              '&:hover': {
                backgroundColor: '#FFFFFF',
              },
            }}
          >
            <Box pl="15px" pr="15px" display="flex" alignItems="center" justifyContent="center">
              <LinkOffIcon sx={{ color: '#ff9191', fontSize: '22px' }} />
            </Box>

            <Box pt="5px" sx={{ flexGrow: 1, flexBasis: '50%' }}>
              <Typography color='#28282C' variant="body2" fontSize='12px' fontWeight='bold'>
                {file.original_filename}
              </Typography>
              <Typography color='#28282C' variant="body2" fontSize='10px'>
                <span>{formatDate(file.created)}</span>
                <span> - </span>
                <span>{translate(getStatusForDescription(file.status_description))}</span>
              </Typography>
            </Box>

            <Box p="5px 15px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <DownloadFile uploadFile={file} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default FailedUploadsLogs