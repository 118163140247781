import { Box, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import {
  MunicipalityGrievanceOutWithGrievance,
  ObjectionGrievanceBase,
  ObjectionOut
} from '../../types/apiRequests'
import { checkObjectionStatus, ObjectionUploadStatus } from '../../utils/checkObjectionStatus'
import Grievance from './Grievance'
import LoadingObjection from './LoadingObjection'

interface Props {
  record: ObjectionOut | undefined;
  objectionGrievances: MunicipalityGrievanceOutWithGrievance[] | undefined;
}

const ObjectionsGrievances: FC<Props> = ({ record, objectionGrievances }) => {
  const translate = useTranslate()
  const objectionUploadStatus = checkObjectionStatus(record)

  const Message: FC<{ text: string }> = ({ text }) => (
    <Typography variant="body1" sx={{ textAlign: 'center', borderRadius: '10px', padding: '20px 10px', backgroundColor: '#f5f5f5' }}>
      {translate(text)}
    </Typography>
  )

  const Content: FC<{objection: ObjectionOut}> = ({objection}) => {
    switch (objectionUploadStatus) {
      case ObjectionUploadStatus.WORKING:
        return (
          <LoadingObjection
            objection={objection}
            onRetry={() => console.log('Not implemented yet')}
          />
        )
      case ObjectionUploadStatus.EXPECTING_FILE:
      case ObjectionUploadStatus.ERROR:
      case ObjectionUploadStatus.NO_GRIEVANCES:
        return (
          <Message text={`resources.objections.grievanceUploadStatus.${objectionUploadStatus}`} />
        )
      case ObjectionUploadStatus.READY:
        return (
          <>
            {objection?.objection_grievances?.map((og: ObjectionGrievanceBase, index: number) => {
              const selectedGrievance = objectionGrievances?.find(gr => gr.grievance.id === og.grievance_id)
              return (
                <Grievance
                  key={og.grievance_id}
                  objectionGrievance={og}
                  index={index}
                  selectedGrievance={selectedGrievance}
                />
              )
            })}
          </>
        )
    }
  }

  if (!record) return <></>

  return (
    <Grid item lg={7} md={6} sm={12}>
      <Box>
        { objectionUploadStatus === ObjectionUploadStatus.READY && (
          <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
            <span>{translate('resources.objections.grieven')}</span>
          </Typography>
        )}
        <Content objection={record} />
      </Box>
    </Grid>
  )
}

export default ObjectionsGrievances