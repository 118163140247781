import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import { Box, Grid, Typography } from '@mui/material'
import { useGetIdentity, useGetList, useGetOne, useRedirect, useTranslate } from 'react-admin'
import { ObjectionOut, StatsOut, UploadFileOut } from '../../types/apiRequests'
import FailedUploadsLogs from './FailedUploads.logs'
import NewObjectionsChart from './NewObjections.linechart'
import ObjectionGrounds from './ObjectionGrounds.piechart'
import ObjectionStatusHeatMap from './ObjectionStatus.heatmap'
import ObjectionType from './ObjectionType.piechart'
import StatBox from './StatBox'
import TopGrievances from './TopGrievances.barchart'

const Dashboard = () => {
  const translate = useTranslate()
  const redirect = useRedirect()
  const { data } = useGetIdentity()
  const { data: objections } = useGetList<ObjectionOut>('objection')
  const { data: uploadFiles } = useGetList<UploadFileOut>('upload_files')
  const { data: statsData } = useGetOne<StatsOut>(
    'stats',
    { id: (data as { id: string })?.id }
  )

  return (
    <Box >
      <Grid container columnSpacing={2}>
        <Grid item xs={6} sm={6} md={4} zeroMinWidth onClick={() => redirect('list', 'objection')}>
          <StatBox
            title="objectionsThisWeek"
            subtitle="new"
            increase={statsData?.objectionsIncrease}
            icon={<FiberNewIcon sx={{ color: '#31679B', fontSize:'30px'}}/>}
            amount={statsData?.newObjections}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} zeroMinWidth onClick={() => redirect('list', 'uploads')}>
          <StatBox
            title="uploadFilesThisWeek"
            subtitle="new"
            increase={statsData?.filesIncrease}
            icon={<FiberNewIcon sx={{ color: '#31679B', fontSize:'30px'}}/>}
            amount={statsData?.newFiles}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} zeroMinWidth onClick={() => redirect('list', 'objection')}>
          <StatBox
            title="objections"
            subtitle="done"
            icon={<AssignmentTurnedInIcon sx={{ color: '#31679B', fontSize:'30px'}}/>}
            rawAmount={statsData?.completed ? `${statsData?.completed}/${statsData?.total}` : ''}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} marginTop='-16px'>
        <Grid item container spacing={2} xs={12} md={8} display='flex' flexDirection='row'>
          <Grid item xs={12} >
            <Box width="100%" height="300px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
              <NewObjectionsChart objections={objections} uploadFiles={uploadFiles} />
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '-16px' }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '-16px' }}>
                  <Box width="100%" height="320px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
                    <Typography
                      variant="h6"
                      style={{ marginLeft: '10px', fontSize: '0.8em', color: '#8E8E93' }}
                    >
                      {translate('dashboard.topGrievances')}
                    </Typography>
                    <TopGrievances grievanceStats={statsData?.topGrievances} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '-16px' }}>
                  <Box width="100%" height="320px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
                    <Typography
                      variant="h6"
                      style={{ marginLeft: '10px', fontSize: '0.8em', color: '#8E8E93' }}
                    >
                      {translate('dashboard.gegrondOngegrond')}
                    </Typography>
                    <ObjectionGrounds objections={objections} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '-16px' }}>
                  <Box width="100%" height="320px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
                    <Typography
                      variant="h6"
                      style={{ marginLeft: '10px', fontSize: '0.8em', color: '#8E8E93' }}
                    >
                      {translate('dashboard.objectionType')}
                    </Typography>
                    <ObjectionType objections={objections} />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ marginTop: '-16px' }}>
                  <Box width="100%" height="320px" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
                    <Typography
                      variant="h6"
                      style={{ marginLeft: '10px', fontSize: '0.8em', color: '#8E8E93' }}
                    >
                      {translate('dashboard.objectionStatus')}
                    </Typography>
                    <ObjectionStatusHeatMap objectionStatus={statsData?.statusCounts} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid item container spacing={2} xs={12} md={4} display='flex' flexDirection='column'>
          <Grid item sx={{ height: '100%' }}>
            <Box width="100%" height="100%" bgcolor="#F9F9F9" borderBottom='2px solid #DDDDE0'>
              <FailedUploadsLogs />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Dashboard