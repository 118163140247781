import { FC } from 'react'
import { EditButton, Show, SimpleShowLayout, useRecordContext } from 'react-admin'
import { ActionsToolbar } from '../../components'
import { ObjectionOut } from '../../types/apiRequests'
import LastUpdated from './LastUpdated'
import ObjectionsForm from './ObjectionsForm'
import ObjectionTitle from './ObjectionTitle'

const ConditionalEditButton: FC = () => {
  const record = useRecordContext<ObjectionOut>()
  return <EditButton variant="contained" label="resources.objections.edit" disabled={!!record?.deleted} />
}

const ObjectionsShow: FC = () => (
  <Show
    actions={<ActionsToolbar>
      <LastUpdated/>
      <ObjectionTitle />
      <ConditionalEditButton />
    </ActionsToolbar>}
  >
    <SimpleShowLayout>
      <ObjectionsForm />
    </SimpleShowLayout>
  </Show>
)

export default ObjectionsShow
