import { styled } from '@mui/material/styles'
import { FC } from 'react'
import {
  Datagrid, DateField, List,
  ListProps, TextField
} from 'react-admin'

const AuditLogsList: FC<ListProps> = (props) => (
  <StyledList
    {...props}
    exporter={false}
    actions={false}
  >
    <Datagrid bulkActionButtons={false}>
      <DateField
        source="created"
        label="data.created"
        showTime={true}
      />
      <TextField
        source="user"
        label="data.user"
      />
      <TextField
        source="type"
        label="data.type"
      />
      <TextField
        source="objection_number"
        label="data.objection_number"
      />
      <TextField
        source="object_woz_number"
        label="data.object_woz_number"
      />
      <TextField
        source="object_reference_number"
        label="data.object_reference_number"
      />
      <TextField
        source="external_reference"
        label="data.externalReference"
      />
    </Datagrid>
  </StyledList>
)

const PREFIX = 'xxllncAuditLogsList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1)
  },
}))

export default AuditLogsList
