import { AttachMoney, Cancel, CheckCircle } from '@mui/icons-material'
import { Box, IconButton, MenuItem, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  ArrayInput, FormDataConsumer, required, SelectInput,
  SimpleFormIterator, TextField, TextInput, useGetList,
  useRecordContext, useSimpleFormIteratorItem, useTranslate
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import { MunicipalityGrievanceOutWithGrievance, ObjectionStatus } from '../../../types/apiRequests'

interface DecisionText {
  id: number
  name: string
  description: string
  valueInfluence: boolean
}

const GrievenEditStep: React.FC = () => {
  const translate = useTranslate()
  const record = useRecordContext()
  const disabled = record?.status !== ObjectionStatus.UNDER_REVIEW

  const { data } = useGetList<MunicipalityGrievanceOutWithGrievance>('municipality_grievances', {
    pagination: { page: 1, perPage: 100 },
    filter: { gemeentecode: `${record?.gemeentecode}` },
  })

  const getFilteredDecisionTexts = (
    mappedDecisionTexts: DecisionText[] | undefined, filter: string
  ) => {
    if (!mappedDecisionTexts) return []
    switch (filter) {
      case 'valueInfluence':
        return mappedDecisionTexts.filter(dt => dt.valueInfluence === true)
      case 'GROUNDED':
        return mappedDecisionTexts.filter(dt => dt.name === 'GROUNDED')
      case 'UNFOUNDED':
        return mappedDecisionTexts.filter(dt => dt.name === 'UNFOUNDED')
      default:
        return mappedDecisionTexts
    }
  }

  return (
    <>
      <Typography variant="subtitle1">{translate('resources.objections.grieven')}</Typography>
      <ArrayInput source="objection_grievances" label={false} >
        <SimpleFormIterator fullWidth>
          <Box display="flex" flexDirection="row">

            <Box display="flex" flexDirection="row" gap={2} flex={1} padding={2}>
              <FormDataConsumer>
                {({ scopedFormData }) => {
                  const [filter, setFilter] = useState<'all' | 'valueInfluence' | 'GROUNDED' | 'UNFOUNDED'>('all')
                  const [selectedDecisionText, setSelectedDecisionText] = useState<string | undefined>(undefined)
                  const { setValue } = useFormContext()
                  const { index } = useSimpleFormIteratorItem()

                  const selectedGrievance = data?.find(gr => gr.grievance.id === scopedFormData?.grievance_id)
                  console.log({
                    scopedFormData,
                    selectedGrievance,
                    data,
                  })
                  const mappedDecisionTexts: DecisionText[] | undefined = selectedGrievance?.grievance?.decision_texts?.map((gr, i) => ({
                    id: i,
                    name: gr.decision,
                    description: gr.description,
                    valueInfluence: gr.valueInfluence,
                  }))

                  useEffect(() => {
                    const filteredDecisionTexts = getFilteredDecisionTexts(mappedDecisionTexts, filter)
                    if (filteredDecisionTexts.length === 1) {
                      setSelectedDecisionText(filteredDecisionTexts[0].id.toString())
                      setValue(`objection_grievances.${index}.decision_text_number`, filteredDecisionTexts[0].id.toString())
                    }
                  }, [filter])

                  return (
                    <>
                      <Box
                        display="flex"
                        alignSelf="flex-start"
                        flexDirection="column"
                        borderRadius="20px"
                        border="thin"
                        bgcolor={scopedFormData?.decision_text_number === null ? '#faebd4' : 'transparent'}
                        mr={2}
                        sx={{ alignSelf: 'center' }}
                      >
                        <Tooltip title={translate('resources.objections.valueInfluence.true')}>
                          <IconButton
                            color={filter === 'valueInfluence' ? 'primary' : 'default'}
                            onClick={() => setFilter(filter === 'valueInfluence' ? 'all' : 'valueInfluence')}
                            disabled={disabled}
                          >
                            <AttachMoney />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={translate('resources.objections.decisions.GROUNDED')}>
                          <IconButton
                            color={filter === 'GROUNDED' ? 'primary' : 'default'}
                            onClick={() => setFilter(filter === 'GROUNDED' ? 'all' : 'GROUNDED')}
                            disabled={disabled}
                          >
                            <CheckCircle />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={translate('resources.objections.decisions.UNFOUNDED')}>
                          <IconButton
                            color={filter === 'UNFOUNDED' ? 'primary' : 'default'}
                            onClick={() => setFilter(filter === 'UNFOUNDED' ? 'all' : 'UNFOUNDED')}
                            disabled={disabled}
                          >
                            <Cancel />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <Box display="flex" flexDirection="column" width="100%">
                        <SelectInput
                          label="resources.objections.grievance"
                          source="grievance_id"
                          validate={required()}
                          choices={data?.map(gr => ({ id: `${gr.grievance.id}`, name: gr.grievance.name })) || []}
                          optionText={(choice: { name: string }) => (
                            <MenuItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  whiteSpace: 'normal',
                                  overflowWrap: 'break-word',
                                  wordBreak: 'break-word',
                                }}
                              >
                                { choice.name}
                              </Typography>
                            </MenuItem>
                          )}
                          disabled={disabled}
                        />

                        <SelectInput
                          label="resources.objections.decisionTexts"
                          source={'decision_text_number'}
                          choices={getFilteredDecisionTexts(mappedDecisionTexts, filter)}
                          optionText={(choice: { name: string, description: string, valueInfluence: boolean }) => (
                            <MenuItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', maxWidth: '500px' }}>
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ fontWeight: 'bold', width: '100%' }}
                              >
                                {
                                  `${translate(`resources.objections.decisions.${choice.name}`)} - 
                                  ${translate(`resources.objections.valueInfluence.${choice.valueInfluence}`)}`
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{
                                  whiteSpace: 'normal',
                                  overflowWrap: 'break-word',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {choice.description}
                              </Typography>
                            </MenuItem>
                          )}
                          value={selectedDecisionText}
                          onChange={(event) => setSelectedDecisionText((event as HTMLInputElement).value)}
                          disabled={disabled}
                        />
                      </Box>
                    </>
                  )
                }}
              </FormDataConsumer>
            </Box>

            <Box flex={1} padding={2} minWidth='35%'>
              <FormDataConsumer>
                {({ scopedFormData }) =>
                  scopedFormData?.id ? (
                    <Box display="flex" flexDirection="column" width="100%">
                      <Typography variant="body1">{translate('resources.objections.citaat')}</Typography>
                      <TextField label="resources.objections.citaat" source="citaat" />
                      <Typography variant="body1" sx={{ marginTop: '10px' }}>{translate('resources.objections.additionalInfo')}</Typography>
                      <TextField label="resources.objections.additionalInfo" source="additional_info" emptyText='-' />
                    </Box>
                  ) : (
                    <>
                      <TextInput label="resources.objections.citaat" source="citaat" validate={required()} />
                      <TextInput label="resources.objections.additionalInfo" source="additional_info" />
                    </>
                  )
                }
              </FormDataConsumer>
            </Box>
          </Box>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  )
}

export default GrievenEditStep
