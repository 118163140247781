import DownloadIcon from '@mui/icons-material/Download'
import { IconButton } from '@mui/material'
import { FC } from 'react'
import { useRecordContext } from 'react-admin'
import { getUrl } from '../../providers/data'
import { UploadFileOut } from '../../types/apiRequests'
import authToken from '../../utils/authToken'

const handleDownload = async (url: string, fileName: string) => {
  const token = authToken.getToken() || ''


  try {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Authorization: token,
        ContentType: 'text/plain',
      },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const blob = await response.blob()
    const downloadUrl = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = downloadUrl
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(downloadUrl)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}

interface Props {
    uploadFile?: UploadFileOut
}

const DownloadFile: FC<Props> = ({ uploadFile }) => {
  const file = uploadFile || useRecordContext<UploadFileOut>()
  const baseUrl = getUrl()
  const downloadUrl = `${baseUrl}/upload_files/${file?.id}/download`
  const fileName = file?.original_filename || ''

  return (
    <IconButton onClick={() => void handleDownload(downloadUrl, fileName)}>
      <DownloadIcon />
    </IconButton>
  )
}

export default DownloadFile