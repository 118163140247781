import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { useRecordContext, useTranslate } from 'react-admin'
import { ObjectionOut } from '../../types/apiRequests'
import { dateFormatter } from '../../utils/dateFormatter'
import StatusIcon from './StatusIcon'

interface LastUpdatedProps {
  children?: React.ReactNode
}

const LastUpdated: FC<LastUpdatedProps> = ({ children }) => {
  const translate = useTranslate()
  const record = useRecordContext<ObjectionOut>()
  const modified = record?.lastModified
  const deleted = record?.deleted

  return (
    <Root className={classes.root}>
      <span className={classes.title}>
        <StatusIcon
          color={false}
          status={record?.status}
          created={record?.created}
        />
        { !!deleted ? `${translate('data.deletedAt')} ${dateFormatter(modified)}` : ''}
        { !deleted && modified ? `${translate('data.lastUpdated')} ${dateFormatter(modified)}` : ''}
      </span>
      {children}
    </Root>
  )
}

const PREFIX = 'xxllncLastUpdated'

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (_props, styles) => styles.root,
})(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexGrow: 2,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    fontSize: '10pt',
    marginBottom: '10px',
  },
}))


export default LastUpdated