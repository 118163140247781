import { FC } from 'react'
import {
  AutocompleteArrayInput, AutocompleteInput, Edit,
  ReferenceArrayInput, ReferenceInput, SelectInput,
  SimpleForm, TextInput
} from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { municipalities } from '../../utils/municipalityCodes'
import { required } from '../../utils/validation'

const MunicipalitiesEdit: FC = () => {
  const sortedMunicipalities = municipalities.sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  return (
    <Edit
      actions={<ActionsToolbar i18n="resources.municipalities.edit" />}
      title={<HeaderTitle i18n="resources.municipalities.edit" />}
    >
      <SimpleForm toolbar={<ConfirmDeleteToolbar access={['configuration:all']} />}>
        <SelectInput
          choices={sortedMunicipalities}
          label="data.municipalityCode"
          source="municipalityCode"
          validate={required()}
          isRequired
        />
        <TextInput source="name" label="data.name" validate={required()} />
        <TextInput source="phoneNumber" label="data.phoneNumber" validate={required()} />
        <ReferenceInput source="courtId" reference="courts">
          <AutocompleteInput optionText="name" label="data.court" validate={required()}/>
        </ReferenceInput>
        <ReferenceArrayInput source="municipality_grievances" reference="grievances" >
          <AutocompleteArrayInput label="data.grievancesBook" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default MunicipalitiesEdit
