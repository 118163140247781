import { ResponsiveHeatMap } from '@nivo/heatmap'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { StatusCounts } from '../../types/apiRequests'

interface Props {
    objectionStatus: StatusCounts[] | undefined
}

const ObjectionStatusHeatMap: FC<Props> = ({ objectionStatus }) => {
  const translate = useTranslate()

  const processData = () => {
    if (!objectionStatus || objectionStatus.length === 0) return []

    return objectionStatus.map(item => ({
      id: translate(`resources.objections.objectionType.${item.id}`),
      data: item.data.map(status => ({
        x: translate(`resources.objections.status.${status.x}`),
        y: status.y
      }))
    }))
  }

  const data = processData()

  const allValues = data.flatMap(d => d.data.map(s => s.y))
  const maxValue = allValues.length > 0 ? Math.max(...allValues) : 1000

  return (
    <ResponsiveHeatMap
      data={data}
      margin={{ top: 40, right: 90, bottom: 80, left: 90 }}
      valueFormat={(value) => String(Math.round(value))}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -30,
        legend: '',
        legendOffset: 46,
        truncateTickAt: 0
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -72,
        truncateTickAt: 0
      }}
      colors={{
        type: 'diverging',
        scheme: 'blues',
        divergeAt: 0.5,
        minValue: 0,
        maxValue
      }}
      emptyColor="#555555"
      labelTextColor={({ value }) => ((value && value > maxValue * 0.5) ? '#fff' : '#000')}
      legends={[
        {
          anchor: 'bottom',
          translateX: 0,
          translateY: 30,
          length: 400,
          thickness: 8,
          direction: 'row',
          tickPosition: 'after',
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: '>-.2s',
          title: '',
          titleAlign: 'start',
          titleOffset: 4
        }
      ]}
    />
  )
}

export default ObjectionStatusHeatMap