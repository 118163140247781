import { ResponsiveLine } from '@nivo/line'
import { FC, useMemo } from 'react'
import { ObjectionOut, UploadFileOut } from '../../types/apiRequests'

interface Props {
  objections: ObjectionOut[] | undefined
  uploadFiles: UploadFileOut[] | undefined
}

export const NewObjectionsChart: FC<Props> = ({ objections, uploadFiles }) => {
  const getLast28Days = (): string[] => {
    const days: string[] = []
    const date = new Date()
    date.setDate(date.getDate() - 27)

    for (let i = 0; i < 28; i++) {
      days.push(date.toISOString().split('T')[0])
      date.setDate(date.getDate() + 1)
    }

    return days
  }

  const processData = (data: (ObjectionOut | UploadFileOut)[] | undefined) => {
    if (!data) return []

    const last28Days = getLast28Days()
    const dayCounts: Record<string, number> = last28Days.reduce((acc, day) => {
      // eslint-disable-next-line security/detect-object-injection
      acc[day] = 0
      return acc
    }, {} as Record<string, number>)

    data.forEach(instance => {
      const date = new Date(instance.created).toISOString().split('T')[0]
      if (dayCounts.hasOwnProperty(date)) {
        // eslint-disable-next-line security/detect-object-injection
        dayCounts[date]++
      }
    })

    return last28Days.map((day) => ({
      x: day,
      // eslint-disable-next-line security/detect-object-injection
      y: dayCounts[day],
    }))
  }

  const processedData = useMemo(() => {
    const objectionsArray = processData(objections)
    const uploadsArray = processData(uploadFiles)

    return [
      {
        id: 'Nieuwe bezwaren',
        color: '#31679B',
        data: objectionsArray,
      },
      {
        id: 'Geüploade bestanden',
        color: '#fbb4ae',
        data: uploadsArray,
      },
    ]
  }, [objections, uploadFiles])

  return (
    <ResponsiveLine
      data={processedData}
      margin={{ top: 30, right: 50, bottom: 80, left: 80 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Datum',
        legendOffset: 50,
        legendPosition: 'middle',
        tickValues: getLast28Days().filter((_, index) => index % 7 === 0)
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Aantal',
        legendOffset: -50,
        legendPosition: 'middle'
      }}
      colors={processedData.map(d => d.color)}
      pointSize={8}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      enableArea={true}
      areaBaselineValue={0}
      useMesh={true}
      tooltip={({ point }) => (
        <div
          style={{
            background: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            color: '#31679B',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)'
          }}
        >
          <strong>{`${point.serieId} - ${Number(point.data.y)}x`}</strong>
        </div>
      )}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 70,
          itemsSpacing: 10,
          itemWidth: 140,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />
  )
}

export default NewObjectionsChart
