import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import * as React from 'react'
import { useRecordContext, useTranslate } from 'react-admin'
import { getUrl } from '../../providers/data'
import { ObjectionFileOut, ObjectionOut } from '../../types/apiRequests'
import authToken from '../../utils/authToken'

const DownloadDocumentSplitButton: React.FC = () => {
  const record = useRecordContext<ObjectionOut>()
  const translate = useTranslate()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const token = authToken.getToken() || ''
  const baseUrl = getUrl()

  const handleDownload = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: token,
          ContentType: 'text/plain',
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = downloadUrl
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const handleMainDownload = async () => {
    const downloadUrl = `${baseUrl}/objection/${record?.id}/pdf`
    const fileName = `objection_${record?.id}.pdf`
    await handleDownload(downloadUrl, fileName)
  }

  const handleMenuItemClick = async (document: ObjectionFileOut) => {
    const downloadUrl = `${baseUrl}/objection_files/${document.id}/download`
    const fileName = document.original_filename
    await handleDownload(downloadUrl, fileName)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  if (!record?.objection_documents || record.objection_documents.length === 0) {
    return null
  }

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        sx={{
          'boxShadow': 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        }}
        aria-label="split button for downloading documents"
      >
        <Button
          variant="outlined"
          sx={{ flexGrow: 2 }}
          onClick={() => void handleMainDownload()}
        >
          <Typography sx={{ fontSize: '0.8125rem', fontWeight: '500', padding: '4px 0' }} textTransform={'capitalize'}>
            {translate('data.downloadObjection_pdf')}
          </Typography>
        </Button>
        <Button
          size="small"
          variant="outlined"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '24px' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {record.objection_documents.map((document) => (
                    <MenuItem
                      key={document.id}
                      onClick={() => void handleMenuItemClick(document)}
                    >
                      { document.file_type ?
                        translate(`resources.objections.fileType.${document.file_type}`) :
                        document.original_filename}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default DownloadDocumentSplitButton
