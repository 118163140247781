import { FileType, ObjectionFileOut, ObjectionOut, ObjectionStatus, ObjectionType } from '../types/apiRequests'

export enum ObjectionUploadStatus {
    WORKING = 'WORKING',
    EXPECTING_FILE = 'EXPECTING_FILE',
    NO_GRIEVANCES = 'NO_GRIEVANCES',
    READY = 'READY',
    ERROR = 'ERROR',
}

const fileOfTypeUploaded = (objectionDocuments: ObjectionFileOut[], fileType: FileType): boolean => {
  let uploaded = false
  objectionDocuments.forEach((file) => {
    if (file.file_type === fileType) {
      uploaded = true
    }
  })
  return uploaded
}

const allFilesUploaded = (objection: ObjectionOut): boolean => {
  const hasBezwaar = fileOfTypeUploaded(objection.objection_documents || [], FileType.BEZWAAR)
  const hasAddition = fileOfTypeUploaded(objection.objection_documents || [], FileType.AANVULLING)

  switch (objection.type) {
    case ObjectionType.PARTICULIER:
    case ObjectionType.EERLIJKE_WOZ:
    case ObjectionType.JUIST:
      return hasBezwaar
    case ObjectionType.HET_NIEUWE_WOZ_BUREAU:
      return hasBezwaar && hasAddition
  }
  return true
}

export const checkObjectionStatus = (objection: ObjectionOut | undefined): ObjectionUploadStatus => {
  if (!objection || objection.status === ObjectionStatus.CANCELLED) return ObjectionUploadStatus.ERROR
  if (objection.status === ObjectionStatus.RECEIVED) return ObjectionUploadStatus.WORKING

  const uploads = allFilesUploaded(objection)
  if (!uploads) return ObjectionUploadStatus.EXPECTING_FILE

  const oneMinuteAgo = new Date(Date.now() - 60 * 1000)
  const recentUpload = objection?.objection_documents?.some((file) => {
    const fileDate = new Date(file.lastModified)
    return fileDate > oneMinuteAgo
  })
  if (recentUpload) return ObjectionUploadStatus.WORKING

  if (objection.objection_grievances?.length > 0) return ObjectionUploadStatus.READY

  return ObjectionUploadStatus.NO_GRIEVANCES
}