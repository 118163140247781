import { ResponsiveBar } from '@nivo/bar'
import { FC } from 'react'
import { GrievanceStats } from '../../types/apiRequests'

interface Props {
  grievanceStats: GrievanceStats[] | undefined
}

const TopGrievances: FC<Props> = ({ grievanceStats }) => {
  const processData = () => {
    if (!grievanceStats || grievanceStats.length === 0) return []

    return grievanceStats.map(grievance => ({
      grievance: grievance.name,
      count: grievance.count
    }))
  }

  const data = processData()

  return (
    <ResponsiveBar
      data={data}
      keys={['count']}
      indexBy="grievance"
      margin={{ top: 20, right: 60, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#31679B']}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      labelTextColor="#fff"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Aantal',
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      tooltip={({ value, indexValue }) => (
        <div
          style={{
            background: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            color: '#31679B',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)'
          }}
        >
          <strong>{indexValue} - {value}x</strong>
        </div>
      )}
      legends={[]}
    />
  )
}

export default TopGrievances
