import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { FilterList, FilterListItem } from 'react-admin'

export const TypeFilter: FC = () => (
  <Root className={classes.root} label="filters.type" icon={undefined}>
    <FilterListItem
      className={classes.item}
      label="resources.objections.objectionType.PARTICULIER"
      value={{ type: 'PARTICULIER' }}
    />
    <FilterListItem
      className={classes.item}
      label="resources.objections.objectionType.EERLIJKE_WOZ"
      value={{ type: 'EERLIJKE_WOZ' }}
    />
    <FilterListItem
      className={classes.item}
      label="resources.objections.objectionType.HET_NIEUWE_WOZ_BUREAU"
      value={{ type: 'HET_NIEUWE_WOZ_BUREAU' }}
    />
    <FilterListItem
      className={classes.item}
      label="resources.objections.objectionType.JUIST"
      value={{ type: 'JUIST' }}
    />
  </Root>
)

const PREFIX = 'xxllncTypeFilter'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
}

const Root = styled(FilterList)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.item}`]: {},
}))
