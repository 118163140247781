import { useEffect, useRef } from 'react'
import { logout } from '..//auth0'
import authToken from '../utils/authToken'

const INACTIVITY_TIME = 15 * 60 * 1000

const useAutoLogout = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      sessionStorage.setItem('logoutReason', 'inactivity')
      void logout()
      authToken.deleteToken()
    }, INACTIVITY_TIME)
  }

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'click']

    const handleActivity = () => resetTimer()

    events.forEach((event) => window.addEventListener(event, handleActivity))
    resetTimer()

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      events.forEach((event) => window.removeEventListener(event, handleActivity))
    }
  }, [])
}

export default useAutoLogout
