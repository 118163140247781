import DoneIcon from '@mui/icons-material/Done'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import { Box, CircularProgress, styled, Typography } from '@mui/material'
import { FC } from 'react'
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  TextField,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { Link } from 'react-router-dom'
import { UploadFileOut, UploadOut, UploadStatus } from '../../types/apiRequests'
import DownloadFile from './DownloadUploadFile'

const UploadsList: FC<ListProps> = (props) => (
  <StyledList
    {...props}
    exporter={false}
    actions={false}
    sort={{ field: 'created', order: 'DESC' }}
  >
    <Datagrid bulkActionButtons={false} expand={<UploadFilesPanel />}>
      <DateField label="data.uploaded" source="created" showTime />
      <FunctionField
        label="data.matchedFiles"
        render={(record: UploadOut) => {
          const totalFiles = record.upload_files?.length || 0
          const matchedFiles = record.upload_files?.filter(f => f.objection_id).length || 0
          return `${matchedFiles}/${totalFiles}`
        }}
      />
      <FunctionField
        label="data.status"
        render={(record: UploadOut) => record.status === UploadStatus.PENDING ? (
          <CircularProgress size="20px" />
        ) : <DoneIcon />}
      />
    </Datagrid>
  </StyledList>
)

const UploadFilesPanel: FC = () => {
  const translate = useTranslate()
  const record = useRecordContext<UploadOut>()

  const LinkField = () => {
    const file = useRecordContext<UploadFileOut>()
    const objectionPage = `/objection/${file?.objection_id}/show`

    return <Link to={objectionPage}><LinkIcon/></Link>
  }

  if (!record || !record.upload_files.length) {
    return (
      <Typography variant="body2" sx={{ p: 2 }}>
        { translate('data.noFiles') }
      </Typography>
    )
  }

  return (
    <Box>
      <Datagrid bulkActionButtons={false} data={record.upload_files} rowClick={false}>
        <FunctionField
          label="data.objection"
          render={(r: UploadFileOut) => r.objection_id ? <LinkField/> : <LinkOffIcon sx={{ color: '#ff9191' }} />}
        />
        <FunctionField
          label="data.fileType"
          render={(r: UploadFileOut) => translate(`resources.objections.fileType.${r.file_type}`)}
        />
        <TextField label="data.fileName" source="original_filename" />
        <TextField label="data.statusDescription" source="status_description" />
        <DownloadFile />
      </Datagrid>
    </Box>
  )
}

const PREFIX = 'xxllncUploadsList'

const classes = {
  spacer: `${PREFIX}-spacer`,
}

const StyledList = styled(List)(({ theme }) => ({
  [`& .${classes.spacer}`]: {
    marginLeft: theme.spacing(1),
  },
}))

export default UploadsList
