import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { FilterList, FilterListItem } from 'react-admin'

export const DeletedFilter: FC = () => (
  <Root className={classes.root} label="filters.deleted" icon={undefined}>
    <FilterListItem
      className={classes.item}
      label="data.excludeDeleted"
      value={{ excludeDeleted: true }}
    />
  </Root>
)

const PREFIX = 'xxllncIncludeDeletedFilter'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
}

const Root = styled(FilterList)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.item}`]: {},
}))
