import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import { FC } from 'react'
import { TextField, TextFieldProps, useRecordContext } from 'react-admin'
import { ObjectionOut } from '../../types/apiRequests'

const StyledTextField = styled(TextField)<{ deleted: string | null | undefined }>(({ theme, deleted }) => ({
  color: deleted ? 'lightgray' : theme.palette.text.primary,
}))

export const ConditionalTextField: FC<TextFieldProps> = (props) => {
  const { className, ...rest } = props
  const record = useRecordContext<ObjectionOut>()

  return (
    <StyledTextField
      {...rest}
      deleted={record?.deleted}
      className={clsx(className)}
    />
  )
}
