import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import FeedbackIcon from '@mui/icons-material/Feedback'
import MoneyIcon from '@mui/icons-material/MonetizationOn'
import { Box, Tooltip } from '@mui/material'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { GrievanceText, MunicipalityGrievanceOutWithGrievance, ObjectionGrievanceBase } from '../../types/apiRequests'

interface GrievanceProps {
  objectionGrievance: ObjectionGrievanceBase;
  index: number;
  selectedGrievance?: MunicipalityGrievanceOutWithGrievance;
}

const Grievance: FC<GrievanceProps> = ({ objectionGrievance: grief, index, selectedGrievance }) => {
  const translate = useTranslate()
  const decisionTexts = selectedGrievance?.grievance?.decision_texts || []
  const isDecisionNumber = typeof grief.decision_text_number === 'number'

  // @ts-expect-error - This should work
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const selectedDecisionText: GrievanceText = isDecisionNumber && decisionTexts[grief.decision_text_number]

  let statusText = ''
  let IconComponent = FeedbackIcon
  // eslint-disable-next-line max-len
  let iconColor: 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined = 'inherit'

  if (isDecisionNumber) {
    if (selectedDecisionText?.valueInfluence) {
      IconComponent = MoneyIcon
      iconColor = 'warning'
      statusText = 'influenced'
    } else if (selectedDecisionText?.decision === 'UNFOUNDED') {
      IconComponent = CancelIcon
      iconColor = 'error'
      statusText = 'unfounded'
    } else if (selectedDecisionText?.decision === 'GROUNDED') {
      IconComponent = CheckCircleIcon
      iconColor = 'success'
      statusText = 'grounded'
    }
  }

  return (
    <Box display="flex" alignItems="flex-start" marginBottom="20px">
      <Tooltip title={translate(`resources.objections.grievanceStatus.${statusText}`)}>
        <IconComponent sx={{ marginRight: '10px', alignSelf: 'flex-start' }} color={iconColor} />
      </Tooltip>
      <Box display="flex" flexDirection="column">
        <span style={{ fontSize: '10pt' }}><b>{`${selectedGrievance?.grievance?.name || ''}`}</b></span>
        <span style={{ fontSize: '10pt' }}>{`${grief.citaat || ''}`}</span>
        { grief.additional_info && <span style={{ fontSize: '9pt', color: '#626262' }}>{`${grief.additional_info}`}</span>}
        {isDecisionNumber && selectedDecisionText && (
          <span style={{ fontSize: '8pt' }}>
            {selectedDecisionText.description}
          </span>
        )}
      </Box>
    </Box>
  )
}

export default Grievance
