import { FC } from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { ActionsToolbar, ConfirmDeleteToolbar, HeaderTitle } from '../../components'
import { required } from '../../utils/validation'

const CourtsEdit: FC = () => (
  <Edit
    actions={<ActionsToolbar i18n="resources.courts.edit" />}
    title={<HeaderTitle i18n="resources.courts.edit" />}
  >
    <SimpleForm toolbar={<ConfirmDeleteToolbar access={['configuration:all']} />}>
      <TextInput source="name" label="data.name" validate={required()} />
      <TextInput source="teamName" label="data.teamName" validate={required()} />
      <TextInput source="mailbox" label="data.mailbox" validate={required()} />
      <TextInput source="postalCode" label="data.postalCode" validate={required()} />
      <TextInput source="city" label="data.city" validate={required()} />
    </SimpleForm>
  </Edit>
)

export default CourtsEdit
