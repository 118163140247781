import { Box, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslate } from 'react-admin'

interface StatBoxProps {
  title: string
  subtitle: string
  icon: any
  amount?: number
  rawAmount?: string
  increase?: string
}

export const StatBox: FC<StatBoxProps> = ({ title, subtitle, icon, amount = 0, rawAmount, increase }) => {
  const translate = useTranslate()
  const options = {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: true,
  }

  const percentageColor = increase?.startsWith('-') ? 'rgb(212 134 128)' : 'rgb(117 170 105)'

  return (
    <Grid
      container
      width="100%"
      height="140px"
      alignContent="center"
      display="flex"
      justifyContent="center"
      sx={{
        'cursor': 'pointer',
        'backgroundColor': '#F9F9F9',
        'overflow': 'hidden',
        'transition': 'border-bottom 0.1s ease',
        'borderBottom': '2px solid #DDDDE0',
        '&:hover': {
          borderBottom: '4px solid #31679B',
        },
      }}
    >

      <Grid
        item
        xs={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        paddingTop={1}
        zeroMinWidth
      >
        <Grid
          display="flex"
          flexDirection="column"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            noWrap
            style={{
              fontSize: '1.2em',
              color: '#8E8E93',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {translate(`data.${subtitle}`)}
          </Typography>

          <Typography
            variant="h6"
            fontWeight="bold"
            noWrap
            style={{
              fontSize: '0.7em',
              color: '#28282C',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {translate(`data.${title}`)}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item xs={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
        zeroMinWidth
      >
        <Grid display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" justifyContent="center">
            {icon}
          </Box>

          <Box display="flex" justifyContent="center">
            <Typography
              noWrap
              variant="h4"
              fontWeight="bold"
              style={{
                color: '#28282C',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {rawAmount ? rawAmount : amount.toLocaleString('en-US', options)}
            </Typography>
          </Box>

          {increase && (
            <Box display="flex" justifyContent="center">
              <Typography
                variant="body2"
                fontWeight="bold"
                style={{
                  color: percentageColor,
                  fontSize: '0.8em',
                }}
              >
                {increase}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StatBox
