import { Alert, Button, TextField, ThemeProvider, Typography } from '@mui/material'
import { createTheme, styled } from '@mui/material/styles'
import { FC, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { Navigate } from 'react-router'
import { getAccessToken, login } from '../auth0'
import { Xxllnc } from '../components'
import { border, button, color, theme as xxTheme, xxllncColor } from '../layout/themes'
import { debug } from '../test'
import authTokenUtil from '../utils/authToken'
import { storeUserDataInLocalStorage } from '../utils/userDetails'

const LoginPage: FC = () => {
  const translate = useTranslate()
  const [isReady, setIsReady] = useState(false)
  const [authToken, setAuthToken] = useState<string | null>(null)
  const [email, setEmail] = useState<string>(authTokenUtil.getOrganization().domain)
  const [logoutReason, setLogoutReason] = useState<string | null>(null)


  useEffect(() => {
    const reason = sessionStorage.getItem('logoutReason')
    if (reason) {
      setLogoutReason(reason)
      sessionStorage.removeItem('logoutReason')
    }
    const getToken = async () => {
      try {
        const token = await getAccessToken()
        if (token) {
          authTokenUtil.setToken(token)
          setAuthToken(token)
          storeUserDataInLocalStorage(token)
        }
      } catch (error) {
        debug && console.log({ error })
      } finally {
        setIsReady(true)
      }
    }

    getToken().catch((error: Error) => {
      debug && console.error(error)
    })
  }, [])

  const onClickLogin = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    event.preventDefault()
    if (email) login(email)
  }

  const onChangeInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEmail(event.target.value as string)
  }

  const isValidEmail = (value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(value)
  }

  debug && console.log({authToken})
  if (authToken) return <Navigate to="/" />

  return (
    <Root className={classes.root}>
      <div className={classes.form}>
        <header className={classes.header}>
          <div className={classes.headerContainer}>
            <h1 className={classes.appLogo}>
              <span className={classes.vignet} aria-hidden="true">Bz</span>
              <span className={classes.appName}>Bezwaren</span>
            </h1>
          </div>
        </header>
        <main className={classes.card}>
          <section>
            {logoutReason === 'inactivity' && (
              <Alert severity="warning" className={classes.alert}>
                {translate('login.inactive')}
              </Alert>
            )}
            <form>
              <Typography
                align="center"
                className={classes.title}
                component="h1"
                variant="h1"
              >
                {translate('login.title')}
              </Typography>
              <TextField
                className={classes.input}
                error={!!email && !isValidEmail(email)}
                label={translate('login.email')}
                helperText={!!email && !isValidEmail(email) ? translate('login.notValid') : undefined}
                onChange={onChangeInput}
                disabled={!(!authToken && isReady)}
                defaultValue={email}
              />
              <Button
                variant="contained"
                type="submit"
                className={classes.button}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onClickLogin}
                disabled={!isReady || !isValidEmail(email)}
                fullWidth
              >
                {translate('login.button')}
              </Button>
            </form>
          </section>
        </main>
        <footer className={classes.footer}>
          <Xxllnc className={classes.logo} />
          <a href="https://xxllnc.nl" className={classes.link} target="_blank">
            {translate('login.byXxllnc')}
          </a>
        </footer>
      </div>
      <div className={classes.layout}>
        <img src='./xxllnc-login-bg.png' className={classes.background} />
      </div>
    </Root>
  )
}

const LoginWithTheme: FC = () => (
  <ThemeProvider theme={createTheme(xxTheme)}>
    <LoginPage />
  </ThemeProvider>
)

const PREFIX = 'xxllncLoginPage'

const classes = {
  root: `${PREFIX}-root`,
  form: `${PREFIX}-form`,
  layout: `${PREFIX}-layout`,
  background: `${PREFIX}-background`,
  header: `${PREFIX}-header`,
  logo: `${PREFIX}-logo`,
  lab: `${PREFIX}-lab`,
  card: `${PREFIX}-card`,
  title: `${PREFIX}-title`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  error: `${PREFIX}-error`,
  errorIcon: `${PREFIX}-errorIcon`,
  actions: `${PREFIX}-actions`,
  headerContainer: `${PREFIX}-headerContainer`,
  appLogo: `${PREFIX}-appLogo`,
  vignet: `${PREFIX}-vignet`,
  appName: `${PREFIX}-appName`,
  alert: `${PREFIX}-alert`,
  footer: `${PREFIX}-footer`,
  link: `${PREFIX}-link`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
    width: '100vw',
    backgroundColor: color.white,
  },
  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: 550
  },
  [`& .${classes.layout}`]: {
    flexGrow: 1,
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },
  [`& .${classes.background}`]: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  [`& .${classes.logo}`]: {
    fill: color.black,
    width: theme.spacing(10),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(1.5),
  },
  [`& .${classes.lab}`]: {
    backgroundColor: xxllncColor.opDark10,
    color: xxllncColor.opDark80,
    borderRadius: border.radius.sm,
    fontSize: 9,
    lineHeight: theme.spacing(1).toString(),
    marginRight: theme.spacing(3),
    marginTop: '2px',
    padding: theme.spacing(.5),
  },
  [`& .${classes.card}`]: {
    borderRadius: 5,
    boxSizing: 'border-box',
    marginBottom: theme.spacing(9),
    minWidth: 400,
    padding: theme.spacing(5),
    margin: 'auto'
  },
  [`& .${classes.title}`]: {
    fontSize: '1.7rem',
    fontWeight: '100',
    textAlign: 'left',
  },
  [`& .${classes.input}`]: {
    boxSizing: 'border-box',
    marginBottom: theme.spacing(3),
    ['& .MuiInputBase-root']: {
      borderRadius: 5,
      margin: 0,
      height: 54,
      ['& input']: {
        height: '100%',
        boxSizing: 'border-box',
      }
    },
    ['& .MuiInputLabel-formControl']: {
      left: theme.spacing(2),
      padding: theme.spacing(1),
      lineHeight: 1,
      transform: 'translate(0, 39%) scale(1)',
    },
    ['& .MuiInputLabel-shrink']: {
      transform: 'translate(-10%, -40%) scale(.88)',
      zIndex: 99,
    },
    ['& .MuiOutlinedInput-input']: {
      background: color.white,
    },
    ['& .Mui-focused']: {
      color: '#28282C',
      border: 0,
      ['& .MuiOutlinedInput-notchedOutline']: {
        border: '1px solid #28282C',
      },
      ['& input']: {
        color: 'rgb(45, 51, 58)',
      }
    },
    ['& .MuiInput-input']: {
      boxSizing: 'border-box',
      height: '100%',
      padding: theme.spacing(1, 1.5),
      width: '100%',
    }
  },
  [`& .${classes.button}`]: {
    ['&.MuiButton-contained']: {
      backgroundColor: xxllncColor.blue100,
      borderRadius: 30,
      color: xxllncColor.base,
      fontSize: 16,
      lineHeight: '16px',
      padding: theme.spacing(2),
      textTransform: 'capitalize',
      ['&:hover']: {
        backgroundColor: button.info.hover.backgroundColor,
        color: button.info.hover.color,
        boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 0px 150px inset',
        transition: 'background-color .25s ease-in-out,box-shadow .25s ease-in-out',
      },
      ['& svg']: {
        color: xxllncColor.light100,
      }
    },
    ['&.Mui-disabled']: {
      backgroundColor: button.info.disabled.backgroundColor,
      color: button.info.disabled.color,
    }
  },
  [`& .${classes.error}`]: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    ['& .MuiTypography-colorError']: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  [`& .${classes.errorIcon}`]: {
    height: 16,
    marginRight: theme.spacing(.5),
    width: 16,
  },
  [`& .${classes.actions}`]: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridArea: 'header',
    padding: '4rem 0',
  },
  [`& .${classes.headerContainer}`]: {
    maxWidth: '24rem',
    flex: 1,
  },
  [`& .${classes.appLogo}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    fontSize: '1.3rem',
    fontWeight: 600,
  },
  [`& .${classes.vignet}`]: {
    backgroundColor: xxllncColor.blue100,
    color: xxllncColor.base,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3rem',
    width: '3rem',
    borderRadius: '1rem',
  },
  [`& .${classes.appName}`]: {
    color: color.black,
    fontSize: '1.3rem',
    fontWeight: 600,
  },
  [`& .${classes.alert}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.footer}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 300
  },
  [`& .${classes.link}`]: {
    color: color.black,
    fontSize: '.75rem',
    lineHeight: 1.5,
    textDecoration: 'none',
    ['&:hover']: {
      textDecoration: 'underline',
    },
  },
}))

export default LoginWithTheme
