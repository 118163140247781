import { ResponsivePie } from '@nivo/pie'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { ObjectionOut } from '../../types/apiRequests'

interface Props {
  objections: ObjectionOut[] | undefined
}

const ObjectionType: FC<Props> = ({ objections }) => {
  const translate = useTranslate()
  const processData = () => {
    if (!objections) return []

    const counts = objections.reduce((acc, objection) => {
      const type = objection.type || 'Onbekend'
      // eslint-disable-next-line security/detect-object-injection
      acc[type] = (acc[type] || 0) + 1
      return acc
    }, {} as Record<string, number>)

    return Object.entries(counts).map(([key, value]) => ({
      id: translate(`resources.objections.objectionType.${key}`),
      label: translate(`resources.objections.objectionType.${key}`),
      value
    }))
  }

  const data = processData()

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 30, right: 80, bottom: 90, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={{ scheme: 'pastel1' }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.6]]
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]]
      }}
      defs={[]}
      fill={[]}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateY: 50,
          itemsSpacing: 10,
          itemWidth: 120,
          itemHeight: 18,
          itemTextColor: '#333',
          symbolSize: 18,
          symbolShape: 'circle'
        }
      ]}
    />
  )
}

export default ObjectionType
