import { ResponsivePie } from '@nivo/pie'
import { FC } from 'react'
import { ObjectionOut, ObjectionStatus } from '../../types/apiRequests'

interface Props {
  objections: ObjectionOut[] | undefined
}

const ObjectionGrounds: FC<Props> = ({ objections }) => {
  const processData = () => {
    if (!objections) return []

    const counts = objections.reduce(
      (acc, objection) => {
        if (objection.status === ObjectionStatus.ACCEPTED) {
          acc.grounded += 1
        } else if (objection.status === ObjectionStatus.REJECTED) {
          acc.unfounded += 1
        }
        return acc
      },
      { grounded: 0, unfounded: 0 }
    )

    return [
      { id: 'Gegrond', label: 'Gegrond', value: counts.grounded },
      { id: 'Ongegrond', label: 'Ongegrond', value: counts.unfounded }
    ]
  }

  const data = processData()

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 30, right: 80, bottom: 90, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={['#ccebc5', '#fbb4ae']}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]]
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]]
      }}
      defs={[]}
      fill={[]}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateY: 50,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#333',
          symbolSize: 18,
          symbolShape: 'circle'
        }
      ]}
    />
  )
}

export default ObjectionGrounds
