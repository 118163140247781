import { required } from 'ra-core'
import { FC } from 'react'
import { ArrayInput, BooleanInput, NumberInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin'
import { ConfirmDeleteToolbar } from '../../components'
import { objectionDecisionChoices } from '../Objections/utils/objectionUtils'


const validateNoCurlyBraces = (value: string) => {
  if (value && /[{}]/.test(value)) {
    return 'Placeholders mmogen geen { of } bevatten. Een placeholder ziet er zo uit: #[placeholder]#'
  }
  if (value && /[<>]/.test(value)) {
    return 'Placeholders mogen geen < of > bevatten. Een placeholder ziet er zo uit: #[placeholder]#'
  }
  if (value && /[*]/.test(value)) {
    return 'Placeholders mogen geen * bevatten. Een placeholder ziet er zo uit: #[placeholder]#'
  }
  return undefined
}


const GrievancesForm: FC = () => (
  <SimpleForm toolbar={<ConfirmDeleteToolbar access={['configuration:all']} />}>
    <NumberInput
      source="grievanceNumber"
      label="data.grievanceNumber"
      validate={required()}
    />
    <TextInput
      source="name"
      label="data.name"
      validate={required()}
    />
    <TextInput source="description"
      label="data.description"
      validate={required()}
      multiline
    />
    <TextInput
      source="explanation"
      label="data.explanation"
      validate={required()}
      multiline
    />
    <ArrayInput
      source="decision_texts"
      label="resources.grievances.decision_texts"
      validate={required()}
    >
      <SimpleFormIterator>
        <SelectInput
          source="decision"
          choices={objectionDecisionChoices}
          label="resources.objections.decision"
          validate={required()}
        />
        <BooleanInput
          label="data.valueInfluence"
          source="valueInfluence"
        />
        <TextInput
          label="data.description"
          source="description"
          multiline
          validate={[required(), validateNoCurlyBraces]}
        />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
)

export default GrievancesForm
