export const getStatusForDescription = (description: string | null | undefined) => {
  if (!description) {
    return 'dashboard.uploadErrors.unknown'
  }
  if (description.includes('Geen bezwaar gevonden voor referentie N/A')) {
    return 'dashboard.uploadErrors.noReferenceFound'
  }
  if (description.includes('Geen bezwaar gevonden')) {
    return 'dashboard.uploadErrors.noObjectionFound'
  }
  if (description.includes('Bezig met verwerken...')) {
    return 'dashboard.uploadErrors.working'
  }
  return 'dashboard.uploadErrors.unknown'
}
